import React from "react"

import { rhythm } from "../utils/typography"
import { ThemeContext } from "./ThemeContext"
import styled from 'styled-components'
import Header from "./header"
import { useStaticQuery, graphql } from "gatsby"
import Footer from './footer';



const themes = {
  light: {
    foreground: "inherit",
    background: "inherit",
  },
  dark: {
    foreground: "#ffffff",
    background: "#282c35",
  },
}

const ThemedLayout = styled.div`
  color: ${props => themes[props.theme.name].foreground};
  background-color: ${props => themes[props.theme.name].background};
  transition: all 0.4s ease;
  min-height: 100vh;

  & a {
    color: ${props => (props.theme.name === "dark" ? "#FFA7C4" : "inherit")};
  }
`

const Layout = ({ location, title, children }) => {
  const data = useStaticQuery(graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`)

  const rootPath = `${__PATH_PREFIX__}/`
  var isRoot = true;

  if(location.pathname !== rootPath) {
    isRoot = false;
  } 

  return (
    <ThemeContext.Consumer>
      {theme => (
        <ThemedLayout theme={theme}>
          
          <div
            style={{
              marginLeft: `auto`,
              marginRight: `auto`,
              maxWidth: rhythm(24),
              padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
              transition: 'color 0.2s ease-out, background 0.2s ease-out'
            }}
          >

            <Header 
            className="site-header"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '2.625rem',
            }}
            siteTitle={data.site.siteMetadata.title} theme={theme} isRoot={isRoot} />

            <main>{children}</main>
            <Footer />
          </div>
        </ThemedLayout>
      )}
    </ThemeContext.Consumer>
  )
}

export default Layout
