import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Switch from "react-switch"
import { rhythm, scale } from "../utils/typography"
import sunIcon from "../images/sun-icon.svg"
import moonIcon from "../images/moon-icon.svg"

const Header = ({ className, siteTitle, theme, isRoot }) => (
    
  <header className={className}>
    {isRoot
        ? <h1
        style={{
          ...scale(0.75),
          marginBottom: rhythm(1.5),
          marginTop: rhythm(0.25),
          display: 'inline-block'
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {siteTitle}
        </Link>
      </h1>
        : <h3
        style={{
          fontFamily: `Montserrat, sans-serif`,
          marginTop: 0,
          marginBottom: 0,
          height: 42, // because
          lineHeight: '2.625rem',
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: 'rgb(255, 167, 196)',
          }}
          to={`/`}
        >
          {siteTitle}
        </Link>
      </h3>
      }

      <div style={{ marginTop: '1em', marginBottom: '-1em', float: 'right' }}>
        <Switch
          onChange={() =>
            theme.updateTheme(theme.name === "light" ? "dark" : "light")
          }
          checked={theme.name === "dark"}
          onColor="#222"
          offColor="#333"
          checkedIcon={<img src={moonIcon} alt="moon icon" />}
          uncheckedIcon={<img src={sunIcon} alt="sun icon" />}
          boxShadow="0 0 2px 3px #FFA7C4"
          activeBoxShadow="0 0 2px 3px #dfb3e6"
        />
      </div>
    
  </header >
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header